import React from 'react';
import './App.css';
// import envelope from "./img/icons/envelope.svg";


function App() {
  return (
    <div className="container">
      <div className="App">
        <header className="App-header">
          Carto Concepts
        </header>
        <div className="App-subheader">Geospatial Software Development</div>
        <div className="App-link-menu">
          Question or project inquiry?
          <button
            className="button icon-button"
            target="_blank"
            onClick={(event) => {
              event.preventDefault();
              window.open("mailto:ishiland@gmail.com?subject=Carto Concepts Inquiry")
            }}>
            Get in touch.
            {/* <img alt='email-link' src={envelope} height="18px"/> */}
          </button>
        </div>
        <div className="Footer">
          <div className="Footer-text">Carto Concepts LLC &#169; 2022</div>
        </div>
      </div>
    </div>
  );
}

export default App;
